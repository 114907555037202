import React from "react";
import SideContent from "./modules/sideContent";
import styled from "styled-components";

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 125px;
  
    @media screen and (max-width: 1725px) {
        margin-left: 0;
    }
  
    @media screen and (max-width: 1110px) {
        margin-right: 0;
    }
  
    @media screen and (max-height: 750px) {
        margin-left: 0;
        margin-right: 0;
    }
  
    @media screen and (max-width: 850px) {
        width: 600px;
    }
  
    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px;
    }
`

const MarginBox = styled.div`
    height: 30px;
`


function Group() {
    return (
        <MainPage>
            <MainContent>
                <MarginBox/>
            </MainContent>
            <SideContent pageType={"group"}/>
        </MainPage>
    )
}

export default Group