import React, {useEffect, useState} from "react";
import styled from "styled-components";
import SideContent from "./modules/sideContent";
import Post from "./modules/post";
import PostWrite from "./modules/postWrite";
import {useParams} from "react-router-dom";
import {collection, doc, getFirestore, onSnapshot} from "firebase/firestore";
import useWindowDimensions from "./modules/getDisplaySize";
import TaggedUser from "./modules/taggedUser";

const TagIcon = require("../images/icons/hash.png")

const MainPage = styled.div`
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 125px;

    @media screen and (max-height: 750px) {
        margin-left: 0;
        margin-right: 0;
    }
  
    @media screen and (max-width: 850px) {
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px;
    }
`

const Margin = styled.div`
    height: 40px;
`

const TagsBox = styled.div`
    width: 750px;
    background-color: white;
    margin: 15px auto 15px;
    box-shadow: 1px 4px 10px #00000028;
    border-radius: 10px;
    padding-top: 13px;
    padding-bottom: 10px;
    
    h1 {
        margin: 0 0 0 18px;
        text-align: center;
    }
  
    @media screen and (max-width: 850px) {
        width: 600px;
    }
  
    @media screen and (max-width: 620px) {
        width: 350px;
    }
`

const TagLayer = styled.a`
    display: flex;
    margin-left: 24px;
    margin-top: 15px;
    color: black;
    text-decoration: none;
  
    img {
        border: 1px solid gray;
        padding: 3px;
        border-radius: 90px;
    }
    
    p {
        margin: 7px 7px 7px 10px;
        font-size: 15px;
    }
`

interface taggedLayerProp {
    tags :any
}

function TaggedLayer({tags} :taggedLayerProp) {
    if (tags !== undefined) {
        if (tags.hashtag.length === 0 && tags.mention.length === 0) {
            return null
        } else {
            return (
                <TagsBox>
                    <h1>Tagged</h1>
                    {tags.hashtag.length !== 0 ? (
                        tags.hashtag.map((e :string, i :number) => (
                            <TagLayer key={i} href={`/search?q=${encodeURIComponent(e)}`}>
                                <img src={TagIcon} alt="hashtag icon" width={"27px"} height={"27px"}/>
                                <p>{e}</p>
                            </TagLayer>
                        ))
                    ) : null}

                    {tags.mention.length !== 0 ? (
                        tags.mention.map((e :string, i :number) => (
                            <TaggedUser id={e} key={i}></TaggedUser>
                        ))
                    ) : null}

                    {tags.hashtag.length === 0 && tags.mention.length === 0 ? (
                        <TagLayer>
                            No tags
                        </TagLayer>
                    ) : null}
                </TagsBox>
            )
        }
    } else {
        return null
    }
}

function PostPage() {
    const {postId} = useParams()
    const {width, height} = useWindowDimensions()
    const [post, setPost] = useState<any>()
    const [comments, setComments] = useState<object[]>([])
    const [tags, setTags] = useState<object | undefined>()
    const [authorProfile, setAuthorProfile] = useState<any>()

    useEffect( () => {
        if (postId !== undefined) {
            const unsub = onSnapshot(doc(getFirestore(), "posts", postId), (doc :any) => {
                if (doc.exists()) {
                    setPost({
                        ...doc.data(),
                        id: doc.id
                    })
                    setTags({hashtag: doc.data().hashtag, mention: doc.data().mention})
                }
            })

            const getComments = onSnapshot(collection(getFirestore(), `posts/${postId}/comments`), (querySnapshot :any) => {
                const posts :any = []
                querySnapshot.forEach((doc :any) => {
                    if (doc.exists) {
                        posts.push({
                            ...doc.data(),
                            id: doc.id
                        })
                    }
                })
                setComments(posts.sort((a :any, b :any) => parseFloat(b.createdAt.seconds) - parseFloat(a.createdAt.seconds)))
            })

            return () => {
                unsub()
                getComments()
            }
        }
    }, [postId])

    useEffect(() => {
        if (post !== undefined) {
            const getAuthor = onSnapshot(doc(getFirestore(), "users", post.author), (doc :any) => {
                if (doc.exists()) {
                    setAuthorProfile(doc.data())
                }
            })

            return(() => getAuthor())
        }
    }, [post])

    console.log(tags)

    return (
        <MainPage>
            <MainContent>
                {post !== undefined ? (
                    <div>
                        <Post post={post} showPostsLength={0} type={"post"} page={"post"}></Post>
                        {width < 1118 || height < 800 ? (
                            <TaggedLayer tags={tags}/>
                        ) : null}
                        <PostWrite isComment={true} postId={[postId]} authorProfile={authorProfile}></PostWrite>
                    </div>
                ) : null}
                {comments.map((post :any, i :number) => (
                    <Post post={post} key={i} showPostsLength={comments.length} type={"comment"} postId={postId} page={"post"}></Post>
                ))}
                <Margin></Margin>
            </MainContent>
            <SideContent pageType={"post"} tags={tags}/>
        </MainPage>
    )
}

export default PostPage