import React, {useState} from "react";
import styled from "styled-components";
import useWindowDimensions from "./getDisplaySize";
import MobileNavbar from "./mobile/mobileNavbar";
import {
    addDoc,
    collection,
    doc,
    getDocs,
    getFirestore,
    increment,
    query,
    updateDoc,
    where
} from "firebase/firestore";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
// @ts-ignore
import Inapp from "detect-inapp"

const Logo = require("../../images/tunip-logo.png")
const SearchIcon = require("../../images/icons/search.png")

const NavbarBox = styled.div `
    width: 100%;
    height: 110px;
    background-color: #ffffffeb;
    top: 0;
    position: fixed;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
`

const LogoImage = styled.img `
    width: 50px;
    height: 50px;
    margin-top: 13px;
`

const LogoBox = styled.a `
    display: flex;
    margin-top: -5px;
    margin-right: 120px;
    margin-left: 0;
    width: 260px;
    color: black;
    text-decoration: none;
  
    @media screen and (max-width: 1725px) {
        width: 40px;
        margin-right: 75px;
    }

    @media screen and (max-height: 800px) {
        width: 40px;
        margin-right: 77px;
    }
  
    h1 {
        margin: 1rem;
        font-weight: bolder;
        
        @media screen and (max-width: 1725px) {
            display: none;
        }
      
        @media screen and (max-height: 800px) {
            display: none;
        }
    }
`

const SearchBox = styled.div `
    background-color: white;
    width: 750px;
    height: 50px;
    border-radius: 90px;
    box-shadow: 1px 4px 10px #00000028;
    margin: 50px 0;

    @media screen and (max-width: 1110px) {
        width: 600px;
    }
  
    @media screen and (max-height: 750px) {
        width: 600px;
    }
    
    img {
        margin-left: 5px;
    }
    
    input {
        position: absolute;
        height: 40px;
        width: 670px;
        border: 0;
        background-color: #00000000;
        font-size: 35px;
        padding-top: 5px;
        outline: none;
      
        @media screen and (max-width: 1110px) {
            width: 500px;
        }

        @media screen and (max-height: 750px) {
            width: 500px;
        }
    }
`

const Margin = styled.div`
    width: 377px;
    @media screen and (max-width: 1455px) {
        display: none;
    }
    
    @media screen and (max-height: 750px) {
        display: none;
    }
`

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

function Navbar() {
    const [keyWord, setKeyWord] = useState<string>("")
    const {width, height} = useWindowDimensions()
    const date :string = new Date().getFullYear().toString() + new Date().getMonth().toString() + new Date().getDate().toString()
    const inapp = new Inapp(navigator.userAgent)

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]
    const navigate = useNavigate()

    async function redirction() {
        let docIdArray :string[] = []
        const querySnapshot = await getDocs(query(collection(getFirestore(), "search"), where("date", "==", date), where("country", "==", userObj.location.country)))
        querySnapshot.forEach((doc) => {
            if (doc.exists()) {
                docIdArray.push(doc.id)
            }
        })

        if (docIdArray.length !== 0) {
            await updateDoc(doc(getFirestore(), "search", docIdArray[0]), {
                [keyWord]: increment(1)
            })
        } else {
            await addDoc(collection(getFirestore(), "search"), {
                [keyWord]: increment(1),
                date: date,
                country: userObj.location.country
            })
        }

        navigate(`/search?q=${encodeURIComponent(keyWord)}`, {state: {userObj}})
    }

    function onSearchKeyDown(event :any) {
        if (event.keyCode === 13) {
            redirction().then(r => r)
        }
    }

    function onSearchClick() {
        redirction().then(r => r)
    }

    function onSearchChange(event :any) {
        setKeyWord(event.target.value)
    }

    function onMainClick() {
        navigate("/", {state: (userObj)})
    }

    if (width > 1118 && height > 750 && !inapp.isMobile) {
        return (
            <NavbarBox>
                <FlexBox>
                    <LogoBox onClick={onMainClick}>
                        <LogoImage src={Logo} alt={"tunip logo"}/>
                        <h1>tunip land</h1>
                    </LogoBox>
                    <SearchBox>
                        <img src={SearchIcon} alt="search icon" width={"50px"} onClick={onSearchClick}/>
                        <input type="text" placeholder={"Search"} onKeyDown={onSearchKeyDown} onChange={onSearchChange}/>
                    </SearchBox>
                    <Margin></Margin>
                </FlexBox>
            </NavbarBox>
        )
    } else {
        return (
            <MobileNavbar></MobileNavbar>
        )
    }
}

export default Navbar