import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {collection, doc, getDoc, getFirestore, onSnapshot} from "firebase/firestore";
import {useSelector} from "react-redux";
import Follow from "../modules/follow";
import {useNavigate} from "react-router-dom";

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 800px;
    max-height: 800px;
    position: fixed;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    text-align: center;
    z-index: 10000;
    overflow-y: auto;

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        border-radius: 30px 30px 0 0;
    }
`

const UserListLayer = styled.div`
    margin-bottom: 20px;
`

const UserLayer = styled.div`
    display: flex;
    width: 70%;
    align-items: center;
    margin: 0 auto 10px;
    justify-content: space-between;
  
    @media screen and (max-width: 850px) {
        width: 90%;
    }
`

const UserProfileLayer = styled.a`
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    text-align: left;
    
    img {
        border-radius: 90px;
    }
    
    p {
        margin: 0 0 0 10px;
        font-size: 20px;
    }

    @media screen and (max-width: 620px) {
        p {
            width: 160px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
`

interface iProp {
    showLikeInfoModal :boolean,
    setShowLikeInfoModal :(active :boolean) => void,
    likeList :object[]
}

function LikeInfoModal({showLikeInfoModal, setShowLikeInfoModal, likeList} :iProp) {
    const [likedUserList, setLikedUserList] = useState<object[]>([])
    const [followingList, setFollowingList] = useState<object[]>([])

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]
    const navigate = useNavigate()

    useEffect( () => {
        let likedUserList :object[] = []

        likeList.map(async (e: any) => {
            await getDoc(doc(getFirestore(), "users", e.userId)).then((doc: any) => {
                if (doc.exists) {
                    likedUserList.push({
                        id: doc.data().id,
                        name: doc.data().name,
                        profilePhoto: doc.data().profilePhoto,
                        userId: doc.data().userId
                    })
                }
            })
        })

        setLikedUserList(likedUserList)
    }, [likeList])

    useEffect(() => {
        const getFollowingList = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/followings`), (querySnapshot) => {
            const followingArray: object[] = []
            querySnapshot.forEach((doc :any) => {
                followingArray.push(doc.data())
            })
            setFollowingList(followingArray)
        })

        return () => getFollowingList()
    }, [userObj])

    function modalOverlayClick() {
        setShowLikeInfoModal(false)
    }

    function onProfileClick(profile :any) {
        navigate(`/profile/${profile.id}`, {state: {profile}})
    }

    if (showLikeInfoModal) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={modalOverlayClick}/>
                <ModalLayer>
                    <h1>Liked users</h1>
                    {likedUserList.length !== 0 ? (
                        <UserListLayer>
                            {likedUserList.map((e :any, i :number) => (
                                <UserLayer key={i}>
                                    <UserProfileLayer onClick={() => onProfileClick(e)}>
                                        <ProfileImage image={e.profilePhoto}/>
                                        <p>{e.name}</p>
                                    </UserProfileLayer>
                                    {e.userId !== userObj.userId ? (
                                        <Follow followingList={followingList} e={e}/>
                                    ) : null}
                                </UserLayer>
                            ))}
                        </UserListLayer>
                    ) : (
                        <p>no liked users</p>
                    )}
                </ModalLayer>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default LikeInfoModal