import React, {useEffect, useState} from "react";
import {collection, doc, getFirestore, onSnapshot, query, where} from "firebase/firestore";
import styled from "styled-components";

const ProfileLayer = styled.div`
    display: flex;
    align-content: center;
    margin-top: 10px;
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(59px, -15px);
  
    @media screen and (max-width: 650px) {
        width: 10px;
        height: 10px;
        transform: translate(49px, -10px);
    }
`

const ProfileId = styled.div`
    margin: 0;
    display: flex;
    
    p {
        font-size: 10px;
        color: #7C7C7C;
        margin: 0 10px 0 0;
    }
  
    @media screen and (max-width: 620px) {
        font-size: 7px;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    margin-left: 24px;
    margin-top: 5px;
  
    @media screen and (max-width: 650px) {
        width: 35px;
        height: 35px;
    }
`

const ProfileNameLayer = styled.p`
    font-size: 20px;
    margin: 0;
  
    @media screen and (max-width: 620px) {
        font-size: 15px;
    }
`

const ProfileNameAndIdLayer = styled.div`
    margin: 11px 11px 0;
    display: block;
    
    @media screen and (max-width: 620px) {
    margin: 7px 7px 7px 10px;
    }
`

interface taggedUserProps {
    id :string
}

function TaggedUser({id} :taggedUserProps) {
    const [profile, setProfile] = useState<any>()
    const [isOnline, setIsOnline] = useState<boolean>(false)

    useEffect(() => {
        if (id !== undefined) {
            const getProfileQuery = query(collection(getFirestore(), "users"), where("id", "==", id.slice(1)))
            const getProfile = onSnapshot(getProfileQuery, (queryShapshot) => {
                queryShapshot.forEach((doc :any) => {
                    if (doc.exists()) {
                        setProfile(doc.data())
                    }
                })
            })

            return(() => getProfile())
        }
    }, [id])

    useEffect(() => {
        if (profile !== undefined) {
            const getStatus = onSnapshot(doc(getFirestore(), "status", profile.userId), (doc :any) => {
                if (doc.exists && doc.data().state === "online") {
                    setIsOnline(true)
                } else {
                    setIsOnline(false)
                }
            })

            return(() => getStatus())
        }
    }, [profile])


    if (profile !== undefined) {
        return (
            <ProfileLayer>
                <a href={"/profile/" + profile.id}>
                    <ProfileImage image={profile.profilePhoto}/>
                    {isOnline ? (
                        <OnlineLight></OnlineLight>
                    ) : null}
                </a>
                <ProfileNameAndIdLayer>
                    <ProfileNameLayer>{profile.name}</ProfileNameLayer>
                    <ProfileId>
                        <p>{"@" + profile.id}</p>
                    </ProfileId>
                </ProfileNameAndIdLayer>
            </ProfileLayer>
        )
    } else {
        return null
    }
}

export default TaggedUser