import React, {useEffect, useState} from "react";
import {doc, getFirestore, onSnapshot} from "firebase/firestore";
import Post from "./post";

interface iProp {
    post :any
}

function ProfileLikedPost({post} :iProp) {
    const [showPost, setShowPost] = useState<any>()

    useEffect(() => {
        if (post !== undefined) {
            const getPost = onSnapshot(doc(getFirestore(), "posts", post), (doc :any) => {
                if (doc.exists) {
                    setShowPost({
                        ...doc.data(),
                        id: doc.id
                    })
                }
            })

            return(() => {
                getPost()
            })
        }
    }, [post])

    if (showPost !== undefined && showPost.content !== undefined) {
        return (
            <Post post={showPost} showPostsLength={0} type={"post"} page={"profile"}/>
            // <div></div>
        )
    } else {
        return null
    }
}

export default ProfileLikedPost