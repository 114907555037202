import React, {useEffect, useState} from "react";
import styled from "styled-components";
import SideContent from "./modules/sideContent";
import {useNavigate, useParams} from "react-router-dom";
import {
    collection,
    getFirestore,
    onSnapshot,
    query,
    where,
    doc,
    setDoc,
    deleteDoc,
    addDoc
} from "firebase/firestore";
import Post from "./modules/post";
import ProfileLikedPost from "./modules/profileLikedPost";
import {useSelector} from "react-redux";
import FollowModal from "./modals/followModal";
import SharePost from "./modules/sharePost";

const FallowIcon = require("../images/icons/follow.png")
const FallowingIcon = require("../images/icons/following.png")

const MainPage = styled.div`
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 125px;
    
    @media screen and (max-width: 1110px) {
        margin-right: 0;
        margin-left: 0;
    }

    @media screen and (max-height: 750px) {
        margin-left: 0;
        margin-right: 0;
    }
  
    @media screen and (max-width: 850px) {
        width: 650px;
    }
  
    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px;
    }
`

const ProfileLayer = styled.div`
    margin-bottom: 10px;
`

const BannerImage = styled.div<{image ?:string}>`
    width: 800px;
    height: 190px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 1px 4px 10px #00000028;
  
    @media screen and (max-width: 850px) {
        width: 650px;
        height: 180px;
    }
  
    @media screen and (max-width: 620px) {
        width: 350px;
        height: 100px;
    }
`

const EmptyBannerImage = styled(BannerImage)`
    background-color: white;
`

const ProfileImageLayer = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 120px;
    box-shadow: 1px 4px 10px #00000020;
    position: absolute;
    top: 320px; 
    //left: 50%;
    transform: translate(323px, -50%);
    background-color: white;
  
    @media screen and (max-width: 850px) {
        transform: translate(225px, -50%);
    }
  
    @media screen and (max-width: 620px) {
        transform: translate(123px, -50%);
        width: 110px;
        height: 110px;
        top: 175px;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 200px;
    height: 200px;
    border-radius: 150px;

    @media screen and (max-width: 620px) {
        width: 110px;
        height: 110px;
    }
`

const UserName = styled.h1`
    font-size: 50px;
    font-weight: bolder;
    margin: 25px 0 0;
    text-align: center;

    @media screen and (max-width: 620px) {
        font-size: 30px;
        font-weight: bold;
        margin-top: 5px;
    }
`

const UserId = styled.p`
    font-size: 20px;
    color: #7C7C7C;
    text-align: center;
    margin: 0;

    @media screen and (max-width: 620px) {
        font-size: 10px;
    }
`

const PageNavigationLayer = styled.div`
    display: flex;
    margin: 0 auto;
    width: 500px;
    text-align: center;

    @media screen and (max-width: 620px) {
        width: 350px;
    }
`

const PageNavigationButton = styled.a`
    width: 500px;
    color: black;
    text-decoration: none;
    font-size: 20px;

    @media screen and (max-width: 620px) {
        width: 350px;
        font-size: 15px;
    }
`

const PageNavigationButtonUnderbar = styled.div`
    background-color: #00d2ff;
    height: 7px;
    width: 60px;
    margin: 0 auto;
    border-radius: 90px;
`

const PostLayer = styled.div`
    margin-bottom: 30px;
`

const FollowLayer = styled.div`
    display: flex;
    float: right !important;
    margin: 15px 25px 15px 15px;
    
    @media screen and (max-width: 620px) {
        margin: 7px;
    }
`

const FollowButton = styled.a`
    cursor: pointer;
    background-color: #00d2ff;
    padding: 7px 15px;
    border-radius: 90px;
    display: flex;
    align-items: center;
      
    p {
        margin: 0 5px;
    }

    @media screen and (max-width: 620px) {
        font-size: 13px;
        padding: 7px 13px;
      
        p {
            margin: 0 2px;
        }
      
        img {
            width: 20px;
        }
    }
`

const FollowInfoLayer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 20px;
  
    a {
        margin-top: 10px;
        color: black;
        text-decoration: none;
        display: flex;
    }
  
    div {
        width: 20px;
    }
  
    a > div {
        width: 10px;
    }
  
    @media screen and (max-width: 850px) {
      margin-bottom: 20px;
      margin-top: 10px;
    }

    @media screen and (max-width: 620px) {
        a {
            font-size: 8px;
            margin-left: 5px;
            margin-top: 5px;
            font-weight: bolder;
        }
      
        div {
            width: 5px;
        }
    }
`

const FollowingButton = styled(FollowButton)`
    background-color: white;
    border: 3px solid #00d2ff;
    padding: 4px 7px;

    @media screen and (max-width: 620px) {
        border: 2px solid #00d2ff;
    }
`

const OnlineLight = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(140px, -60px);

    @media screen and (max-width: 620px) {
        width: 30px;
        height: 30px;
        transform: translate(80px, -30px);
    }
`

const ProfileNameMargin = styled.div`
    height: 100px;

    @media screen and (max-width: 620px) {
        height: 50px;
    }
`

const PrivateLayer = styled.div`
    margin-top: 30px;
    text-align: center;
`

function Profile() {
    const {id} = useParams()
    const {page} = useParams()
    const [profile, setProfile] = useState<any>(undefined)
    const [showPosts, setShowPosts] = useState<object[]>([])
    const [postArray, setPostArray] = useState<object[]>([])
    const [likedPosts, setLikedPosts] = useState<any>([])
    const [padsArray, setPadsArray] = useState<any>([])
    const [isFollowing, setIsFollowing] = useState<boolean>(false)
    const [follower, setFollower] = useState<object[]>([])
    const [following, setFollowing] = useState<object[]>([])
    const [showFollowModal, setShowFollowModal] = useState<boolean>(false)
    const [followModalType, setFollowModalType] = useState<string>("")
    const [followUserArray, setFollowUserArray] = useState<object[]>([])
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [isPrivate, setIsPrivate] = useState<boolean>(false)

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]
    const navigate = useNavigate()

    useEffect(() => {
        if (id !== undefined) {
            const q = query(collection(getFirestore(), "users"), where("id", "==", id))
            const getProfile = onSnapshot(q, (querySnapshot :any) => {
                let user :any = []
                querySnapshot.forEach((doc :any) => {
                    if (doc.exists) {
                        user.push(doc.data())
                    }
                })

                if (user.length !== 0) {
                    setProfile(user[0])
                } else {
                    setProfile(undefined)
                }
            })

            return(() => {
                getProfile()
            })
        }
    } ,[])

    // if (profile !== undefined) {
    //     fetch(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${profile.location.placeId}&key=AIzaSyCcX67loAS8ClbEOiTpfn1Zb2ayiBTHftQ`, {
    //         method: 'GET'
    //     }).then((response) => {
    //         return response.json()
    //     }).then((json) => {
    //         setLocationName(json.results[0].formatted_address)
    //     })
    // }

    useEffect(() => {
        if (profile !== undefined) {
            const getPosts = onSnapshot(collection(getFirestore(), "posts"), (snapshot) => {
                const posts :object[] = []
                snapshot.forEach((doc) => {
                    if (doc.exists()) {
                        posts.push({
                            ...doc.data(),
                            id: doc.id
                        })
                    }
                })
                setPostArray(posts)
            })

            const getLikedPosts = onSnapshot(doc(getFirestore(), "liked", profile.userId), (doc :any) => {
                if (doc.exists()) {
                    setLikedPosts(doc.data().postLiked.reverse())
                }
            })

            const padsQuery = query(collection(getFirestore(), "pads"), where("maker", "==", profile.userId))
            const getPads = onSnapshot(padsQuery, (querySnapshot :any) => {
                const pads :any = []
                querySnapshot.forEach((doc :any) => {
                    if (doc.exists) {
                        pads.push(doc.data())
                    }
                })
                setPadsArray(pads)
            })

            const getFollower = onSnapshot(collection(getFirestore(), `users/${profile.userId}/followers`), (querySnapshot) => {
                let followerArray :object[] = []
                querySnapshot.forEach((doc :any) => {
                    if (doc.exists) {
                        followerArray.push(doc.data())
                    }
                })

                setFollower(followerArray)

                if (followerArray.find((e :any) => e.userId === userObj.userId) !== undefined) {
                    setIsFollowing(true)
                } else {
                    setIsFollowing(false)
                }
            })

            const getFollowing = onSnapshot(collection(getFirestore(), `users/${profile.userId}/followings`), (querySnapshot) => {
                let followingArray :object[] = []
                querySnapshot.forEach((doc :any) => {
                    if (doc.exists) {
                        followingArray.push(doc.data())
                    }
                })

                setFollowing(followingArray)
            })

            // const accountCreatedDate = new Date(profile.createdAt.seconds * 1000)
            // setAccountCreatedDate(monthArr[accountCreatedDate.getMonth()] + " " + accountCreatedDate.getDate().toString() + ", " + accountCreatedDate.getFullYear().toString())

            const getStatus = onSnapshot(doc(getFirestore(), "status", profile.userId), (doc :any) => {
                if (doc.exists && doc.data().state === "online") {
                    setIsOnline(true)
                } else {
                    setIsOnline(false)
                }
            })

            return(() => {
                getPosts()
                getLikedPosts()
                getPads()
                getFollower()
                getFollowing()
                getStatus()
            })
        }
    }, [profile])

    useEffect(() => {
        if (follower.find((e :any) => e.userId === userObj.userId) !== undefined) {
            setIsFollowing(true)
        } else {
            setIsFollowing(false)
        }
    }, [follower, userObj])

    useEffect(() => {
        setShowPosts(postArray.filter((e :any) => e.author === profile.userId || e.mention.indexOf("@" + profile.id) > -1))
    }, [postArray])

    useEffect(() => {
        if (profile !== undefined) {
            if (profile.private && profile.userId !== userObj.userId) {
                if (following.find((e :any) => e.userId === userObj.userId) !== undefined) {
                    setIsPrivate(false)
                } else {
                    setIsPrivate(true)
                }
            } else {
                setIsPrivate(false)
            }
        }
    }, [profile, following, userObj])

    async function onFollowClick() {
        if (!isFollowing) {
            await addDoc(collection(getFirestore(), `users/${profile.userId}/notifications`), {
                content: `${userObj.name} started following you`,
                date: new Date(),
                isChecked: false,
                redirection: `/profile/${userObj.id}`,
                from: userObj.userId
            })

            await setDoc(doc(getFirestore(), `users/${profile.userId}/followers`, userObj.userId), {
                userId: userObj.userId,
                followedAt: new Date()
            })

            await setDoc(doc(getFirestore(), `users/${userObj.userId}/followings`, profile.userId), {
                userId: profile.userId,
                followedAt: new Date()
            })
        }
    }

    async function onFollowingClick() {
        if (isFollowing) {
            await deleteDoc(doc(getFirestore(), `users/${profile.userId}/followers`, userObj.userId))
            await deleteDoc(doc(getFirestore(), `users/${userObj.userId}/followings`, profile.userId))
        }
    }

    function onFollowingInfoClick() {
        setShowFollowModal(true)
        setFollowModalType("Following")
        setFollowUserArray(following)
    }

    function onFollowerInfoClick() {
        setShowFollowModal(true)
        setFollowModalType("Follower")
        setFollowUserArray(follower)
    }

    function followModalReset() {
        setShowFollowModal(false)
        setFollowModalType("")
        setFollowUserArray([])
    }

    function onPostClick() {
        navigate(`/profile/${id}/main`, {state: {profile}})
    }

    function onLikeClick() {
        navigate(`/profile/${id}/liked`, {state: {profile}})
    }

    return (
        <MainPage>
            {profile === undefined ? (
                <MainContent>
                    <ProfileLayer>user not found</ProfileLayer>
                </MainContent>
            ) : (
                <MainContent>
                    {showFollowModal ? (
                        <FollowModal type={followModalType} userArray={followUserArray} setShowFollowModal={setShowFollowModal} followModalReset={followModalReset}/>
                    ) : null}
                    <ProfileLayer>
                        {profile.bannerPhoto !== null ? (
                            <BannerImage image={profile.bannerPhoto}/>
                        ) : (
                            <EmptyBannerImage/>
                        )}
                        <ProfileImageLayer>
                            <ProfileImage image={profile.profilePhoto}/>
                            {isOnline ? (
                                <OnlineLight></OnlineLight>
                            ) : null}
                        </ProfileImageLayer>
                        <FollowLayer>
                            {profile.userId !== userObj.userId && !isFollowing ? (
                                <FollowButton onClick={onFollowClick}>
                                    <img src={FallowIcon} alt="fallow icon" width={"30px"}/>
                                    <p>Follow</p>
                                </FollowButton>
                            ) : profile.userId !== userObj.userId && isFollowing ? (
                                <FollowingButton onClick={onFollowingClick}>
                                    <img src={FallowingIcon} alt="fallowing icon" width={"30px"}/>
                                    <p>Following</p>
                                </FollowingButton>
                            ) : null}
                        </FollowLayer>
                        <ProfileNameMargin/>
                        <UserName>{profile.name}</UserName>
                        <UserId>{"@" + profile.id}</UserId>
                        <FollowInfoLayer>
                            <a onClick={onFollowerInfoClick}>Followers<div/>{follower.length}</a>
                            <div></div>
                            <a onClick={onFollowingInfoClick}>Following<div/>{following.length}</a>
                        </FollowInfoLayer>
                        <PageNavigationLayer>
                            <PageNavigationButton onClick={onPostClick}>
                                Posts
                                {page === "main" ? (
                                    <PageNavigationButtonUnderbar></PageNavigationButtonUnderbar>
                                ) : null}
                            </PageNavigationButton>
                            <PageNavigationButton onClick={onLikeClick}>
                                Liked
                                {page === "liked" ? (
                                    <PageNavigationButtonUnderbar></PageNavigationButtonUnderbar>
                                ) : null}
                            </PageNavigationButton>
                            <PageNavigationButton>
                                Photos
                                {page === "photo" ? (
                                    <PageNavigationButtonUnderbar></PageNavigationButtonUnderbar>
                                ) : null}
                            </PageNavigationButton>
                        </PageNavigationLayer>
                    </ProfileLayer>
                    {!isPrivate ? (
                        <div>
                            {page === "main" ? (
                                <PostLayer>
                                    {showPosts.sort((a :any, b :any) => parseFloat(b.createdAt.seconds) - parseFloat(a.createdAt.seconds)).map((post :any, i :number) => (
                                        <div key={i}>
                                            {post.type === "text" || post.type === "file" || post.type === "gif" ? (
                                                <Post post={post} showPostsLength={showPosts.length} type={"post"} page={"profile"}/>
                                            ) : post.type === "share" ? (
                                                <SharePost post={post}/>
                                            ) : "error"}
                                        </div>
                                    ))}
                                </PostLayer>
                            ) : page === "liked" ? (
                                <PostLayer>
                                    {likedPosts.map((e :string, i :number) => (
                                        <ProfileLikedPost post={e} key={i}/>
                                    ))}
                                </PostLayer>
                            ) : null}
                        </div>
                    ) : (
                        <PrivateLayer>
                            <p>This profile is setted private</p>
                        </PrivateLayer>
                    )}
                    <div style={{height: "30px"}}></div>
                </MainContent>
            )}
            <SideContent pageType={"profile"} padsArray={padsArray}/>
        </MainPage>
    )
}

export default Profile