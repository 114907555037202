import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const CommentIcon = require("../../../images/icons/comment.png")
const HomeIcon = require("../../../images/icons/home.png")
const SearchIcon = require("../../../images/icons/search.png")
const ProfileIcon = require("../../../images/icons/account.png")

const NavbarBox = styled.div `
    width: 100%;
    height: 50px;
    background-color: #ffffffeb;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
`

const NavbarItem = styled.a`
    margin: 0 auto;
`

function BottomBar() {
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]
    const navigate = useNavigate()

    function onProfileClick() {
        if (userObj.id !== undefined) {
            navigate(`/profile/${userObj.id}/main`, {state: {userObj}})
        }
    }

    function onNavClick(page :string) {
        navigate(`/${page}`, {state: {userObj}})
    }

    return (
        <NavbarBox>
            <NavbarItem onClick={() => onNavClick("")}>
                <img src={HomeIcon} alt="profile icon" width={"35px"}/>
            </NavbarItem>
            <NavbarItem onClick={() => onNavClick("explore")}>
                <img src={SearchIcon} alt="search icon" width={"35px"}/>
            </NavbarItem>
            <NavbarItem onClick={onProfileClick}>
                <img src={ProfileIcon} alt="profile icon" width={"30px"}/>
            </NavbarItem>
            <NavbarItem href={"https://tunip.chat/"}>
                <img src={CommentIcon} alt="chat icon" width={"35px"}/>
            </NavbarItem>
        </NavbarBox>
    )
}

export default BottomBar