import React from "react";
import {useSelector} from "react-redux";
import {addDoc, collection, deleteDoc, doc, getFirestore, setDoc} from "firebase/firestore";
import styled from "styled-components";

const FollowBtn = styled.a`
    cursor: pointer;
    background-color: #00d2ff;
    padding: 4px 12px;
    border-radius: 90px;
    display: flex;
    align-items: center;
    border: 3px solid #00d2ff;
      
    p {
        margin: 3px 5px;
    }
`

const FollowingBtn = styled.a`
    cursor: pointer;
    background-color: white;
    padding: 4px 12px;
    border-radius: 90px;
    display: flex;
    align-items: center;
    border: 3px solid #00d2ff;
      
    p {
        margin: 3px 5px;
    }
`

interface iProp {
    followingList :object[],
    e :any
}

function FollowingButton(profile :any) {
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    async function onFollowingClick() {
        await deleteDoc(doc(getFirestore(), `users/${profile.profile.userId}/followers`, userObj.userId))
        await deleteDoc(doc(getFirestore(), `users/${userObj.userId}/followings`, profile.profile.userId))
    }

    return (
        <FollowingBtn onClick={onFollowingClick}>
            <p>Following</p>
        </FollowingBtn>
    )
}

function FollowButton(profile :any) {
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    async function onFollowClick() {
        await addDoc(collection(getFirestore(), `users/${profile.profile.userId}/notifications`), {
            content: `${userObj.name} started following you`,
            date: new Date(),
            isChecked: false,
            redirection: `/profile/${userObj.id}`,
            from: userObj.userId
        })

        await setDoc(doc(getFirestore(), `users/${profile.profile.userId}/followers`, userObj.userId), {
            userId: userObj.userId,
            followedAt: new Date()
        })

        await setDoc(doc(getFirestore(), `users/${userObj.userId}/followings`, profile.profile.userId), {
            userId: profile.profile.userId,
            followedAt: new Date()
        })
    }

    return (
        <FollowBtn onClick={onFollowClick}>
            <p>Follow</p>
        </FollowBtn>
    )
}

function Follow({followingList, e} :iProp) {
    return (
        followingList.find((o :any) => o.userId === e.userId) ? (
            <FollowingButton profile={e}/>
        ) : (
            <FollowButton profile={e}/>
        )
    )
}

export default Follow