import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import AccountSetup from "../modals/accountSetup";
import { getDatabase, ref, onValue } from "firebase/database";
import useWindowDimensions from "../modules/getDisplaySize"
import BottomBar from "./mobile/bottomBar";
import {useNavigate} from "react-router-dom";
// @ts-ignore
import Inapp from "detect-inapp"

const ProfileIcon = require("../../images/icons/account.png")
const NotificationIcon = require("../../images/icons/notification.png")
const CommentIcon = require("../../images/icons/comment.png")
const GroupIcon = require("../../images/icons/group.png")
require("../../images/icons/hash.png");
const SettingIcon = require("../../images/icons/setting.png")
const SearchIcon = require("../../images/icons/search.png")

const SideBarBox = styled.div `
    width: 300px;
    margin-left: 80px;
  
    @media screen and (max-width: 1725px) {
        width: 80px;
    }
`

const NavigationBox = styled.div `
    margin-top: 125px;
    position: fixed;
`

const NavigationButton = styled.a `
    border-radius: 90px;
    display: flex;
    padding: 10px 15px;
    text-decoration: none;
    color: black;
    width: auto;
    margin-bottom: 10px;
    transition: 0.3s;
    
    h1 {
        margin: 6px 6px 6px 10px;
        font-size: 30px;
        font-weight: bolder;
      
        @media screen and (max-width: 1725px) {
            display: none;
        }
    }
  
    &:hover {
        background-color: whitesmoke;
    }
`

const AccountBox = styled(NavigationButton) `
    display: flex;
    padding: 15px;
    bottom: 0;
    margin-bottom: 20px;
    width: 270px;
    position: fixed;
    
    img {
        border-radius: 90px;
    }
    
    div > h3 {
        margin: 0;
    }
    
    div > p {
        color: #7c7c7c;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 230px;
    }
`

const AccountBoxDitail = styled.div`
    margin: 2px 2px 2px 10px;
    
    @media screen and (max-width: 1725px) {
        display: none;
    }
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(25px, 33px);
    margin: 2px 2px 2px 10px;
`

function SideBar() {
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const navigate = useNavigate()
    const inapp = new Inapp(navigator.userAgent)

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]
    const {width, height} = useWindowDimensions()

    useEffect(() => {
        const getStatus = onValue(ref(getDatabase(), `status/${userObj.userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val()) {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        return (() => getStatus())
    }, [userObj])

    function onProfileClick() {
        if (userObj.id !== undefined) {
            navigate(`/profile/${userObj.id}/main`, {state: {userObj}})
        }
    }

    function onNavClick(page :string) {
        navigate(`/${page}`, {state: {userObj}})
    }

    if (width > 1118 && height > 750 && !inapp.isMobile) {
        return (
            <SideBarBox>
                <NavigationBox>
                    <NavigationButton onClick={onProfileClick}>
                        <img src={ProfileIcon} alt="profile icon" width={"50px"}/>
                        <h1>Profile</h1>
                    </NavigationButton>
                    <NavigationButton onClick={() => onNavClick("notification")}>
                        <img src={NotificationIcon} alt="notification icon" width={"50px"}/>
                        <h1>Notification</h1>
                    </NavigationButton>
                    <NavigationButton href={"#"}>
                        <img src={GroupIcon} alt="group icon" width={"50px"}/>
                        <h1>Groups</h1>
                    </NavigationButton>
                    <NavigationButton onClick={() => onNavClick("explore")}>
                        <img src={SearchIcon} alt="search icon" width={"50px"}/>
                        <h1>Explore</h1>
                    </NavigationButton>
                    <NavigationButton href={"https://tunip.chat/"}>
                        <img src={CommentIcon} alt="tag icon" width={"50px"}/>
                        <h1>Chat</h1>
                    </NavigationButton>
                    <NavigationButton onClick={() => onNavClick("setting")}>
                        <img src={SettingIcon} alt="setting icon" width={"50px"}/>
                        <h1>Settings</h1>
                    </NavigationButton>
                </NavigationBox>
                <AccountBox href={"#"}>
                    <img src={userObj.profilePhoto} alt="user profile photo" width={"50px"}/>
                    {isOnline ? (
                        <OnlineLight></OnlineLight>
                    ) : null}
                    <AccountBoxDitail>
                        <h3>{userObj.name}</h3>
                        <p>{"@" + userObj.id}</p>
                    </AccountBoxDitail>
                </AccountBox>
                <AccountSetup/>
            </SideBarBox>
        )
    } else {
        return (
            <>
                <BottomBar></BottomBar>
                <AccountSetup/>
            </>
        )
    }
}

export default SideBar