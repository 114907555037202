import React, {useEffect, useState} from "react";
import styled from "styled-components";
import SideContent from "./modules/sideContent";
import PostWrite from "./modules/postWrite";
import {collection, getFirestore, onSnapshot, DocumentData} from "firebase/firestore";
import {useSelector} from "react-redux";
import Post from "./modules/post";
import SharePost from "./modules/sharePost";

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 125px;
  
    @media screen and (max-height: 750px) {
        margin-left: 0;
        margin-right: 0;
    }
  
    @media screen and (max-width: 850px) {
        width: 650px;
        margin-left: 0;
        margin-right: 0;
    }
  
    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px;
    }
`

const MarginBox = styled.div`
    height: 30px;
`

function Main() {
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]
    const [showPosts, setShowPosts] = useState<any>([])
    const [followingUser, setFollowingUser] = useState<string[]>([])
    const [followingTag, setFollowingTag] = useState<string[]>([])
    const [postArray, setPostArray] = useState<object[]>([])

    useEffect(() => {
        const getFollowing = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/followings`), (querySnapshot) => {
            let followingArray :string[] = [userObj.userId]
            querySnapshot.forEach((doc :DocumentData) => {
                if (doc.exists) {
                    followingArray.push(doc.data().userId)
                }
            })

            setFollowingUser(followingArray)
        })

        const getHashtagFollowing = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/hashtagFollowings`), (querySnapshot) => {
            let followingArray :string[] = []
            querySnapshot.forEach((doc :DocumentData) => {
                if (doc.exists) {
                    followingArray.push(doc.data().tagName)
                }
            })

            setFollowingTag(followingArray)
        })

        return () => {
            getFollowing()
            getHashtagFollowing()
        }
    }, [userObj])

    useEffect(() => {
        const getPosts = onSnapshot(collection(getFirestore(), "posts"), (snapshot) => {
            const posts :object[] = []
            snapshot.forEach((doc) => {
                if (doc.exists()) {
                    posts.push({
                        ...doc.data(),
                        id: doc.id
                    })
                }
            })
            setPostArray(posts)
        })

        return () => getPosts()
    }, [userObj])

    useEffect(() => {
        if (postArray.length !== 0) {
            setShowPosts(postArray.filter((e :any) => e.author === userObj.userId || e.mention.indexOf("@" + userObj.id) > -1 || followingUser.indexOf(e.author) > -1))
        }
    }, [postArray])

    return (
        <MainPage>
            <MainContent>
                <PostWrite isComment={false}/>
                {showPosts
                    .sort((a :any, b :any) => parseFloat(b.createdAt.seconds) - parseFloat(a.createdAt.seconds))
                    .map((post :any, i :number) => (
                        <div key={i}>
                            {post.type === "text" || post.type === "file" || post.type === "gif" ? (
                                <Post post={post} showPostsLength={showPosts.length} type={"post"} page={"main"}/>
                            ) : post.type === "share" ? (
                                <SharePost post={post}/>
                            ) : "error"}
                        </div>
                ))}
                <MarginBox></MarginBox>
            </MainContent>
            <SideContent pageType={"main"}/>
        </MainPage>
    )
}

export default Main