import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
    addDoc,
    collection,
    doc,
    getDocs,
    getFirestore,
    increment,
    onSnapshot,
    query,
    updateDoc,
    where
} from "firebase/firestore";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const URLIcon = require("../../images/icons/link.png")
const ProfileIcon = require("../../images/icons/account.png")

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 800px;
    max-height: 800px;
    position: fixed;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    text-align: center;
    z-index: 10000;
    overflow-y: auto;

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        border-radius: 30px 30px 0 0;
    }
`

const OptionListLayer = styled.div`
    margin-bottom: 30px;
    cursor: pointer;
`

const OptionLayer = styled.div`
    display: flex;
    width: 70%;
    align-items: center;
    margin: 0 auto 10px;
  
    p {
        font-size: 20px;
        margin-left: 15px;
    }
  
    @media screen and (max-width: 850px) {
        width: 90%;
    }
`

const OptionIcon = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 90px;
    background-color: #ececec;
    padding: 5px;
`

interface shareModalProp {
    showShareModal :boolean,
    setShowShareModal :(active :boolean) => void,
    post :any
}

function ShareModal({showShareModal, setShowShareModal, post} :shareModalProp) {
    const [isCopied, setIsCopied] = useState<boolean>(false)
    const [follower, setFollower] = useState<object[]>([])
    const navigate = useNavigate()

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        const getFollower = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/followers`), (querySnapshot) => {
            let followerArray :object[] = []
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    followerArray.push(doc.data())
                }
            })

            setFollower(followerArray)
        })

        return(() => getFollower())
    }, [userObj])

    function onOverlayClick() {
        setShowShareModal(false)
    }

    function onCopyClick() {
        navigator.clipboard.writeText(`https://tunip.land/post/${post.id}`).then(r => r)
        setIsCopied(true)
    }

    function sendNotification() {
        if (follower.length !== 0) {
            follower.map((e :any) => {
                addDoc(collection(getFirestore(), `users/${e.userId}/notifications`), {
                    content: `${userObj.name} shared a post`,
                    date: new Date(),
                    isChecked: false,
                    redirection: `/profile/${userObj.id}`,
                    from: userObj.userId
                }).then(r => r)
            })
        }
    }

    async function onShareOnProfileClick() {
        const date :string = new Date().getFullYear().toString() + new Date().getMonth().toString() + new Date().getDate().toString()

        await addDoc(collection(getFirestore(), "posts"), {
            author: userObj.userId,
            createdAt: new Date(),
            location: userObj.location,
            content: post.id,
            keyword: post.keyword,
            hashtag: post.hashtag,
            mention: post.mention,
            type: "share"
        }).then(() => {
            sendNotification()
        })

        if (post.hashtag.length !== 0) {
            post.hashtag.map(async (e :string) => {
                let docIdArray :string[] = []
                const querySnapshot = await getDocs(query(collection(getFirestore(), "today"), where("date", "==", date), where("country", "==", userObj.location.country)))
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        docIdArray.push(doc.id)
                    }
                })

                if (docIdArray.length !== 0) {
                    await updateDoc(doc(getFirestore(), "today", docIdArray[0]), {
                        [e]: increment(1)
                    })
                } else {
                    await addDoc(collection(getFirestore(), "today"), {
                        [e]: increment(1),
                        date: date,
                        country: userObj.location.country
                    })
                }
            })
        }

        if (post.mention.length !== 0) {
            post.mention.map(async (e :string) => {
                let docIdArray :string[] = []
                const querySnapshot = await getDocs(query(collection(getFirestore(), "today"), where("date", "==", date), where("country", "==", userObj.location.country)))
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        docIdArray.push(doc.id)
                    }
                })

                if (docIdArray.length !== 0) {
                    await updateDoc(doc(getFirestore(), "today", docIdArray[0]), {
                        [e]: increment(1)
                    })
                } else {
                    await addDoc(collection(getFirestore(), "today"), {
                        [e]: increment(1),
                        date: date,
                        country: userObj.location.country
                    })
                }
            })
        }

        navigate("/")
    }

    if (showShareModal) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={onOverlayClick}/>
                <ModalLayer>
                    <h1>Share post</h1>
                    <OptionListLayer>
                        <OptionLayer onClick={onCopyClick}>
                            <OptionIcon src={URLIcon} alt="url icon"/>
                            {isCopied ? (
                                <p>Copied link✨</p>
                            ) : (
                                <p>Copy link</p>
                            )}
                        </OptionLayer>
                        <OptionLayer onClick={onShareOnProfileClick}>
                            <OptionIcon src={ProfileIcon} alt={"profile icon"}/>
                            <p>Share on your profile</p>
                        </OptionLayer>
                    </OptionListLayer>
                </ModalLayer>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default ShareModal