//❤️

import React, {useEffect} from "react";
import {BrowserRouter, Route, Routes, Navigate, useParams} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "@firebase/auth"
import {getDoc, doc, setDoc, collection, getFirestore, onSnapshot} from "firebase/firestore"
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getDatabase, ref, set, serverTimestamp, onValue, onDisconnect } from "firebase/database";

import Login from "./login";
import Navbar from "./modules/navbar";
import Main from "./main"
import SideBar from "./modules/sideBar";
import Post from "./postPage"
import Profile from "./profile";
import Search from "./search";
import Notification from "./notification";
import Setting from "./setting";
import Explore from "./explore";
import Group from "./group";
import ProfileSetting from "./settings/profileSetting";
import LocationSetting from "./settings/locationSetting";
import NotificationSetting from "./settings/notificationSetting";

const FlexBox = styled.div `
    display: flex;
    height: 100%;
`

function ProfileRedirect() {
    const {id} = useParams()

    return(
        <Navigate replace to={`/profile/${id}/main`}></Navigate>
    )
}

function AppRouter() {
    const auth = getAuth()
    const dispatch = useDispatch()
    const data = useSelector((state => state))
    // @ts-ignore
    const [isLoggedIn, userObj] = [data.isLoggedIn, data.userObj]

    //account setup
    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const idList = await getDoc(doc(getFirestore(), "users", "idList"))
                dispatch({type: "SET isLoggedIn", input: true})

                const unsub = onSnapshot(doc(getFirestore(), "users", user.uid), async (profileDoc) => {
                    if (profileDoc.exists()) {
                        if (idList.exists()) {
                            if (idList.data().list.find((Element: string) => Element === profileDoc.data().id)) {
                                dispatch({type: "SET accountSetupComplete", input: true})
                            } else {
                                dispatch({type: "SET accountSetupComplete", input: false})
                            }
                        }
                        dispatch({type: "SET userObj", input: profileDoc.data()})
                    } else {
                        dispatch({type: "SET accountSetupComplete", input: false})

                        await setDoc(doc(collection(getFirestore(), "users"), user.uid), {
                            createdAt: new Date(),
                            email: user.email,
                            id: user.uid,
                            name: user.displayName,
                            profilePhoto: user.photoURL,
                            userId: user.uid
                        })

                        dispatch({
                            type: "SET userObj", input: {
                                createdAt: new Date(),
                                email: user.email,
                                id: user.uid,
                                name: user.displayName,
                                profilePhoto: user.photoURL,
                                userId: user.uid
                            }
                        })
                    }
                })

                return () => unsub()
            } else {
                dispatch({type: "SET isLoggedIn", input: false})
            }
        })
    }, [])

    function rtdb_and_local_fs_presence() {
        const uid = userObj.userId;
        const userStatusDatabaseRef = ref(getDatabase(), "/status/" + uid)

        const isOfflineForDatabase = {
            state: 'offline',
            last_changed: serverTimestamp(),
        };

        const isOnlineForDatabase = {
            state: 'online',
            last_changed: serverTimestamp(),
        };

        const userStatusFirestoreRef = ref(getDatabase(), "/status/" + uid);
        const isOfflineForFirestore = {
            state: 'offline',
            last_changed: serverTimestamp(),
        };

        const isOnlineForFirestore = {
            state: 'online',
            last_changed: serverTimestamp(),
        };

        onValue(ref(getDatabase(), ".info/connected"), (snapshot) => {
            if (snapshot.val() == false) {
                set(userStatusFirestoreRef, isOfflineForFirestore).then(r => r)
                return;
            }

            onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase).then(() => {
                set(userStatusDatabaseRef, isOnlineForDatabase).then(r => r)
                set(userStatusFirestoreRef, isOnlineForFirestore).then(r => r)
            })
        })
    }

    rtdb_and_local_fs_presence();

    return(
        <BrowserRouter>
            <Routes>
                {isLoggedIn ? (
                    <>
                        <Route path={"/"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <Main/>
                                </FlexBox>
                            </>
                        }/>
                        <Route path={"/post/:postId"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <Post/>
                                </FlexBox>
                            </>
                        }/>
                        <Route path={"/profile/:id/:page"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <Profile/>
                                </FlexBox>
                            </>
                        }/>
                        <Route path={"/profile/:id"} element={
                            <ProfileRedirect></ProfileRedirect>
                        }/>
                        <Route path={"/search"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <Search/>
                                </FlexBox>
                            </>
                        }/>
                        <Route path={"/explore"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <Explore/>
                                </FlexBox>
                            </>
                        }/>
                        <Route path={"/notification"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <Notification/>
                                </FlexBox>
                            </>
                        }/>
                        <Route path={"/group"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <Group/>
                                </FlexBox>
                            </>
                        }/>
                        <Route path={"/setting"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <Setting/>
                                </FlexBox>
                            </>
                        }/>
                        <Route path={"/setting/profile"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <ProfileSetting/>
                                </FlexBox>
                            </>
                        }/>
                        <Route path={"/setting/location"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <LocationSetting/>
                                </FlexBox>
                            </>
                        }/>
                        <Route path={"/setting/notification"} element={
                            <>
                                <Navbar/>
                                <FlexBox>
                                    <SideBar/>
                                    <NotificationSetting/>
                                </FlexBox>
                            </>
                        }/>
                    </>
                ) : (
                    <Route path={"/"} element={
                        <div className={"App"}>
                            <Login/>
                        </div>
                    }/>
                )}
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter