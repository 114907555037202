import React from "react";
import styled from "styled-components";
import PhotoComment from "../modules/photoComment";

const LikeIcon = require("../../images/icons/like.png")
const CommentIcon = require("../../images/icons/comment.png")
const ShareIcon = require("../../images/icons/shere.png")
const LikedIcon = require("../../images/icons/liked.png")

interface IProp {
    photoModalShow :boolean,
    setPhotoModalShow :(active :boolean) => void,
    post :any,
    authorProfile :any,
    createdDate :string,
    isLiked :boolean,
    onLikeClick :() => Promise<void>,
    onUnLikeClick :() => Promise<void>,
    likeList :any,
    comments :any
}

const PhotoModal = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
    position: fixed;
    z-index: 10000;
  
    @media screen and (max-width: 1000px) {
        background-color: rgba(0, 0, 0, 0.6);
    }
`

const PhotoContent = styled.div<{image :string}>`
    width: 100%;
    display: flex;
    align-items: center;
    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`

const PostComments = styled.div`
    width: 500px;
    background-color: white;
  
    @media screen and (max-width: 1000px) {
        display: none;
    }
`

const PostContent = styled.div`
    
`

const AuthorNameLayer = styled.p`
    font-size: 20px;
    margin: 0;
`

const AuthorIdAndDate = styled.div`
    margin: 0;
    display: flex;
    
    p {
        font-size: 10px;
        color: #7C7C7C;
        margin: 0 10px 0 0;
    }
`

const ProfileLayer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
  
    img {
        border-radius: 90px;
        margin-left: 24px;
        margin-top: 12px;
    }
`

const PostContentLayer = styled.div`
    margin-left: 24px;
    margin-top: 10px;
    margin-bottom: 7px;
    
    p {
        white-space: pre-wrap;
        font-size: 20px;
        margin: 0;
    }
`

const PostCommentLayer = styled.div`
    border-top: 1px solid gray;
    width: 90%;
    display: flex;
    margin: 0 auto 10px;
    
    a {
        display: flex;
        margin: 3px auto;
        color: black;
        text-decoration: none;
    }
`

const PostLikeInfoLayer = styled.div`
    width: 100%;
    margin-left: 20px;
    font-size: 13px;
    color: #7C7C7C;
    display: flex;

    div {
        width: 10px;
    }
`

const PhotoCommentLayer = styled.div`

`

function PostPhotoModal({photoModalShow, setPhotoModalShow, post, authorProfile, createdDate, isLiked, onUnLikeClick, onLikeClick, likeList, comments} :IProp) {
    function onOverlayClick() {
        setPhotoModalShow(false)
    }

    if (photoModalShow) {
        return (
            <PhotoModal>
                <PhotoContent onClick={onOverlayClick} image={post.file}/>
                <PostComments>
                    <PostContent>
                        <ProfileLayer>
                            <img src={authorProfile.profilePhoto} alt="author profile photo" width={"50px"} height={"50px"}/>
                            <div style={{margin: "11px", marginBottom: "0"}}>
                                <AuthorNameLayer>{authorProfile.name}</AuthorNameLayer>
                                <AuthorIdAndDate>
                                    <p>{"@" + authorProfile.id}</p>
                                    <p>{createdDate}</p>
                                </AuthorIdAndDate>
                            </div>
                        </ProfileLayer>
                        <PostContentLayer>
                            <p>{post.content}</p>
                        </PostContentLayer>
                        <PostLikeInfoLayer>
                            {likeList.length !== 0 ? (
                                likeList.length + " likes"
                            ) : null}
                            <div></div>
                            {comments.length !== 0 ? (
                                comments.length + " comments"
                            ) : null}
                        </PostLikeInfoLayer>
                        <PostCommentLayer>
                            {isLiked ? (
                                <a style={{cursor: "pointer"}} onClick={onUnLikeClick}>
                                    <img src={LikedIcon} alt="like icon" width={"30px"}/>
                                </a>
                            ) : (
                                <a style={{cursor: "pointer"}} onClick={onLikeClick}>
                                    <img src={LikeIcon} alt="like icon" width={"30px"}/>
                                </a>
                            )}
                            <a href={`/post/${post.id}/`}>
                                <img src={CommentIcon} alt="comment icon" width={"30px"}/>
                            </a>
                            <a style={{cursor: "pointer"}}>
                                <img src={ShareIcon} alt="share icon" width={"30px"}/>
                            </a>
                        </PostCommentLayer>
                        <PhotoCommentLayer>
                            {comments.map((e: any, i :number) => (
                                <PhotoComment key={i} comment={e}/>
                            ))}
                        </PhotoCommentLayer>
                    </PostContent>
                </PostComments>
            </PhotoModal>
        )
    } else {
        return null
    }
}

export default PostPhotoModal