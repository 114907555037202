import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth"
import useWindowDimensions from "./modules/getDisplaySize";
// @ts-ignore
import Inapp from "detect-inapp"

const Desert = require("../images/desert.png")
const DesertMobile = require("../images/desert-mobile.png")
const Banner = require("../images/banner.png")

const Background = styled.div<{mobile :boolean}>`
    width: 100%;
    height: 100%;
    background-image: url(${(props => props.mobile ? DesertMobile : Desert)});
    background-size: cover;
    background-position: center;
`

const Over = styled.div `
    width: 100%;
    height: 100%;
    //background-color: rgba(0,0,0,0.21);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`

const LoginButton = styled.a `
    position: relative;
    display: inline-block;
    padding: 15px 70px;
    color: #9af317;
    text-transform: uppercase;
    letter-spacing: 7px;
    text-decoration: none;
    font-size: 24px;
    border: 3px solid #9af317;
    margin-top: 30px;
  
    @media screen and (max-width: 610px) {
        padding: 10px 35px;
        font-size: 14px;
        margin-top: 20px;
        letter-spacing: 5px;
    }
`


const MainContent = styled.div `
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
  
    img {
        @media screen and (max-width: 610px) {
            width: 300px;
        }
    }
`

function Login() {
    const {width} = useWindowDimensions()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < 630) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width])

    function onGoogleClick() {
        const provider = new GoogleAuthProvider()
        signInWithPopup(getAuth(), provider).then(r => r)
    }

    if (navigator.userAgent.match(/inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|Whale|kakaostory|band|twitter|DaumApps|DaumDevice|Facebook|FB|facebook/i)) {
        window.location.href='intent://tunip.land#Intent;scheme=http;package=com.android.chrome;end'
    }

    return (
        <Background mobile={isMobile}>
            <Over>
                <MainContent>
                    <img src={Banner} alt="banner" width={"600px"}/>
                    <div/>
                    <LoginButton href={"#"} onClick={onGoogleClick}>
                        Log in
                    </LoginButton>
                </MainContent>
            </Over>
        </Background>
    )
}

export default Login