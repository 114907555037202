import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {getFirestore, onSnapshot, doc, collection} from "firebase/firestore";
import {useSelector} from "react-redux";
import Follow from "../modules/follow";
import {useNavigate} from "react-router-dom";

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 800px;
    max-height: 800px;
    position: fixed;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    z-index: 10000;
    overflow-y: auto;
  
    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        border-radius: 30px 30px 0 0;
    }
`

const UserListLayer = styled.div`
    margin-bottom: 30px;
`

const UserLayer = styled.div`
    display: flex;
    width: 70%;
    align-items: center;
    margin: 0 auto 10px;
    justify-content: space-between;
  
    @media screen and (max-width: 850px) {
        width: 90%;
    }
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(59px, -15px);
  
    @media screen and (max-width: 850px) {
        transform: translate(35px, -15px);
    }
`

const ProfileId = styled.div`
    margin: 0;
    display: flex;
    
    p {
        font-size: 10px;
        color: #7C7C7C;
        margin: 0 10px 0 0;
    }
`

const ProfileNameLayer = styled.p`
    font-size: 20px;
    margin: 0;
  
    @media screen and (max-width: 620px) {
        width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`

const UserInfoLayer = styled.div`
    display: flex;
    align-content: center;
  
    a {
        cursor: pointer;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    margin-left: 24px;
    margin-top: 5px;
  
    @media screen and (max-width: 850px) {
        margin-left: 0;
    }
`

const ModalTitle = styled.h1`
    text-align: center;
`

interface followModalProp {
    type :string,
    userArray :object[],
    setShowFollowModal :(active :boolean) => void,
    followModalReset :() => void
}


interface userLayerProp {
    userId :string
}

function UserLayerComponent({userId} :userLayerProp) {
    const [profile, setProfile] = useState<any>()
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [followingList, setFollowingList] = useState<object[]>([])

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]
    const navigate = useNavigate()

    useEffect(() => {
        const getProfile = onSnapshot(doc(getFirestore(), "users", userId), (doc) => {
            if (doc.exists()) {
                setProfile(doc.data())
            }
        })

        const getStatus = onSnapshot(doc(getFirestore(), "status", userId), (doc :any) => {
            if (doc.exists && doc.data().state === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        return(() => {
            getProfile()
            getStatus()
        })
    }, [userId])

    useEffect(() => {
        const getFollowingList = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/followings`), (querySnapshot) => {
            const followingArray: object[] = []
            querySnapshot.forEach((doc :any) => {
                followingArray.push(doc.data())
            })
            setFollowingList(followingArray)
        })

        return () => getFollowingList()
    }, [userObj])

    function onProfileClick() {
        navigate(`/profile/${profile.id}`, {state: {profile}})
    }

    if (profile !== undefined) {
        return (
            <UserLayer>
                <UserInfoLayer>
                    <a onClick={onProfileClick}>
                        <ProfileImage image={profile.profilePhoto}/>
                        {isOnline ? (
                            <OnlineLight></OnlineLight>
                        ) : null}
                    </a>
                    <div style={{margin: "11px", marginBottom: "0", display: "block"}}>
                        <ProfileNameLayer>{profile.name}</ProfileNameLayer>
                        <ProfileId>
                            <p>{"@" + profile.id}</p>
                        </ProfileId>
                    </div>
                </UserInfoLayer>
                {userId !== userObj.userId ? (
                    <Follow followingList={followingList} e={profile}/>
                ) : null}
            </UserLayer>
        )
    } else {
        return null
    }
}

function FollowModal({type, userArray, setShowFollowModal, followModalReset} :followModalProp) {
    function onModalOverlayClick() {
        followModalReset()
        setShowFollowModal(false)
    }

    return (
        <ModalOverBox>
            <ModalOverlay onClick={onModalOverlayClick}/>
            <ModalLayer>
                {type === "Follower" ? (
                    <ModalTitle>Followers</ModalTitle>
                ) : (
                    <ModalTitle>Following users</ModalTitle>
                )}
                {userArray.length !== 0 ? (
                    <UserListLayer>
                        {userArray.map((e :any, i :number) => (
                            <UserLayerComponent userId={e.userId} key={i}/>
                        ))}
                    </UserListLayer>
                ) : null}
            </ModalLayer>
        </ModalOverBox>
    )
}

export default FollowModal