import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Post from "./post";
import {collection, deleteDoc, doc, getDoc, getDocs, getFirestore} from "firebase/firestore";
import {useSelector} from "react-redux";
const BinIcon = require("../../images/icons/bin.png")

const ShareMessage = styled.div`
    width: 750px;
    border-radius: 10px;
    margin: 0 auto;
    background-color: whitesmoke;
    display: flex;
    justify-content: space-between;
  
    p {
        padding: 10px;
        margin: 0;
    }

    @media screen and (max-width: 850px) {
        width: 600px;
    }
  
    @media screen and (max-width: 620px) {
        width: 350px;
    }
`

const DeleteMessage = styled.div`
    text-align: center;
`

const PostMenuItem = styled.a`
    display: flex;
    cursor: pointer;
    align-items: center;
  
    img {
        margin-right: 5px;
    }
    
    p {
        margin: auto;
    }
`

interface PostProp {
    post :any
}

interface MenuProps {
    showMenu :boolean,
    onDeleteClick ?:() => void
}

function Menu({showMenu, onDeleteClick} :MenuProps) {
    if (showMenu) {
        return (
            <PostMenuItem onClick={onDeleteClick}>
                <img src={BinIcon} alt="bin icon" width={"30px"} height={"30px"}/>
            </PostMenuItem>
        )
    } else {
        return null
    }
}

function SharePost({post} :PostProp) {
    const [postData, setPostData] = useState<any>()
    const [profile, setProfile] = useState<any>()
    const [postDeleted, setPostDeleted] = useState<boolean>(false)
    const [followings, setFollowings] = useState<string[]>([])
    const [isPrivate, setIsPrivate] = useState<boolean>(false)
    const [showAuthorMenu, setShowAuthorMenu] = useState<boolean>(false)

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        if (post.content !== undefined && post.author !== undefined) {
            getDoc(doc(getFirestore(), "posts", post.content)).then((doc) => {
                if (doc.exists()) {
                    setPostData({
                        id: doc.id,
                        ...doc.data()
                    })
                } else {
                    setPostDeleted(true)
                }
            })

            getDoc(doc(getFirestore(), "users", post.author)).then((doc) => {
                if (doc.exists()) {
                    setProfile(doc.data())
                }
            })

            getDocs(collection(getFirestore(), `users/${post.author}/followings`)).then((querySnapshot) => {
                const followingsArray :string[] = []
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        followingsArray.push(doc.data().userId)
                    }
                })
                setFollowings(followingsArray)
            })

            if (post.author === userObj.userId) {
                setShowAuthorMenu(true)
            } else {
                setShowAuthorMenu(false)
            }
        }
    }, [post])

    useEffect(() => {
        if (profile !== undefined && userObj.userId !== undefined) {
            if (profile.private && profile.userId !== userObj.userId) {
                if (followings.find((e :string) => e === userObj.userId) !== undefined) {
                    setIsPrivate(false)
                } else {
                    setIsPrivate(true)
                }
            } else {
                setIsPrivate(false)
            }
        }
    }, [profile, followings, userObj])

    async function onDeleteClick() {
        const ok = window.confirm("Are you sure want to delete this post?")
        if (ok) {
            await deleteDoc(doc(getFirestore(), "posts", post.id))
        }
    }

    if (postData !== undefined && profile !== undefined && !isPrivate) {
        return (
            <div>
                <ShareMessage>
                    <p>{profile.name} shared a post</p>
                    <Menu showMenu={showAuthorMenu} onDeleteClick={onDeleteClick}/>
                </ShareMessage>
                <Post post={postData} showPostsLength={0} type={"share"} page={"main"}/>
            </div>
        )
    } else if (profile !== undefined && postDeleted) {
        return (
            <div>
                <ShareMessage>
                    <p>{profile.name} shared a post</p>
                    <Menu showMenu={showAuthorMenu} onDeleteClick={onDeleteClick}/>
                </ShareMessage>
                <DeleteMessage>
                    Cannot find this post
                </DeleteMessage>
            </div>
        )
    } else {
        return null
    }
}

export default SharePost