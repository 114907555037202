import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {deleteDoc, doc, getFirestore, setDoc} from "firebase/firestore";

const FollowBtn = styled.a`
    cursor: pointer;
    background-color: #00d2ff;
    padding: 4px 12px;
    border-radius: 90px;
    display: flex;
    align-items: center;
    border: 3px solid #00d2ff;
      
    p {
        margin: 3px 5px;
    }
`

const FollowingBtn = styled.a`
    cursor: pointer;
    background-color: white;
    padding: 4px 12px;
    border-radius: 90px;
    display: flex;
    align-items: center;
    border: 3px solid #00d2ff;
      
    p {
        margin: 3px 5px;
    }
`

interface tagFollowProp {
    e :string,
    tagFollowingList :string[]
}

function FollowingButton(profile :any) {
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    async function onFollowingClick() {
        await deleteDoc(doc(getFirestore(), `users/${userObj.userId}/hashtagFollowings`, profile.profile.slice(1)))
    }

    return (
        <FollowingBtn onClick={onFollowingClick}>
            <p>Following</p>
        </FollowingBtn>
    )
}

function FollowButton(profile :any) {
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    async function onFollowClick() {
        await setDoc(doc(getFirestore(), `users/${userObj.userId}/hashtagFollowings`, profile.profile.slice(1)), {
            tagName: profile.profile,
            followedAt: new Date()
        })
    }

    return (
        <FollowBtn onClick={onFollowClick}>
            <p>Follow</p>
        </FollowBtn>
    )
}

function TagFollow({e, tagFollowingList} :tagFollowProp) {
    return (
        tagFollowingList.find((o :any) => o === e) ? (
            <FollowingButton profile={e}/>
        ) : (
            <FollowButton profile={e}/>
        )
    )
}

export default TagFollow