import React, {useEffect, useState} from 'react';
import './style/App.css';
import AppRouter from "./router/router";

import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { getAnalytics } from "firebase/analytics"
import {useDispatch, useSelector} from "react-redux";
import { arrayUnion, doc, getFirestore, updateDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCG8L4vH4SoY6XdDcv4RsE8HIx8csUSt1I",
    authDomain: "a20201126.firebaseapp.com",
    databaseURL: "https://a20201126.firebaseio.com",
    projectId: "a20201126",
    storageBucket: "a20201126.appspot.com",
    messagingSenderId: "1023670349812",
    appId: "1:1023670349812:web:97ed61793ea9598b0fd3a1",
    measurementId: "G-PF4NPXDD4M"
}

const app = initializeApp(firebaseConfig)
getDatabase(app)
getAnalytics(app)

function App() {
    const dispatch = useDispatch()
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj, isLoggedIn] = [data.userObj, data.isLoggedIn]
    const [token, setToken] = useState<string>("")

    useEffect(() => {
        if (token !== "") {
            updateDoc(doc(getFirestore(), "users", userObj.userId), {
                token: arrayUnion(token)
            }).then(r => r)

            dispatch({type: "SET token", input: token})
        }
    }, [token])

    if (navigator.vendor.indexOf("Apple") < 0 && isLoggedIn) {
        if (window.location.protocol === "https:" || window.location.hostname === "localhost") {
            const messaging = getMessaging(app)
            getToken(messaging, {vapidKey: "BFsfBZmjdfC2QB5gDy-cUzAsmI04X1-XPfJ3vnfGAJ7DmbP2SbaOAUoXBdl72t3sApyNX8j8tGVqRm6MhvyjJaI"}).then((currentToken) => {
                if (currentToken !== undefined && userObj.userId !== undefined) {
                    setToken(currentToken)
                }
            }).catch(e => console.error(e))

            onMessage(messaging, (payload) => {
                console.log(payload);
            });

            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                }
            })
        }
    }

    return (
        <div className="App">
            <AppRouter/>
        </div>
    );
}

export default App;
