import React, {useEffect, useState} from "react";
import SideContent from "./modules/sideContent";
import styled from "styled-components";
import {collection, deleteDoc, doc, getFirestore, onSnapshot, updateDoc} from "firebase/firestore";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 125px;
  
    @media screen and (max-height: 750px) {
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 850px) {
        width: 650px;
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px
    }
`

const PageTitle = styled.h1`
    font-size: 60px;
    font-weight: lighter;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;

    @media screen and (max-width: 620px) {
        font-size: 40px;
    }
`

const NotificationLayer = styled.div`
    width: 700px;
    height: 90px;
    box-shadow: 1px 4px 10px #00000028;
    border-radius: 10px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 0 auto 25px;
    
    div {
        display: flex;
        align-content: center;
    }
    
    p {
        margin: auto 0;
        font-size: 20px;
    }

    @media screen and (max-width: 850px) {
        width: 650px;
    }

    @media screen and (max-width: 620px) {
        width: 350px;
      
        p {
            font-size: 15px;
        }
    }
`

const NotificationDate = styled.div`
    p {
        font-size: 15px;
        margin-right: 20px;
        color: #7c7c7c;

        @media screen and (max-width: 620px) {
            font-size: 10px;
        }
    }
`

const NotificationContent = styled.p<{isChecked :boolean}>`
    overflow: hidden;
    white-space: nowrap;
    width: 480px;
    text-overflow: ellipsis;
    color: ${props => props.isChecked ? "gray" : "black"};
    font-weight: ${props => props.isChecked ? "nomal" : "bolder"};
  
    @media screen and (max-width: 620px) {
        width: 200px;
    }
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(59px, 55px);

    @media screen and (max-width: 620px) {
        transform: translate(40px, 52px);
        width: 10px;
        height: 10px;
    }
`

const DeleteAllButton = styled.div`
    text-align: right;
    width: 90%;
    margin-bottom: 20px;
  
    a {
        cursor: pointer;
    }
  
    @media screen and (max-width: 850px) {
        width: 99%;
    }
  
    @media screen and (max-width: 620px) {
        width: 95%;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    margin: auto 24px;
  
    @media screen and (max-width: 620px) {
        width: 35px;
        height: 35px;
        margin: auto 15px;
    }
`

interface notificationDetailProp {
    notification :any
}

function NotificationDetail({notification} :notificationDetailProp) {
    const [profile, setProfile] = useState<any>()
    const [date, setDate] = useState<string>("")
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const navigate = useNavigate()

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        const getProfile = onSnapshot(doc(getFirestore(), "users", notification.from), (doc) => {
            if (doc.exists()) {
                setProfile(doc.data())
            }
        })

        const birthdayDate = new Date(notification.date.seconds * 1000)
        let monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        setDate(monthArr[birthdayDate.getMonth()] + ", " + birthdayDate.getDate().toString() + ", " + birthdayDate.getFullYear().toString())

        return(() => getProfile())
    }, [notification])

    useEffect(() => {
        const getStatus = onSnapshot(doc(getFirestore(), "status", notification.from), (doc :any) => {
            if (doc.exists && doc.data().state === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        return(() => getStatus())
    })

    async function onNotificationClick() {
        await updateDoc(doc(getFirestore(), `users/${userObj.userId}/notifications`, notification.id), {
            isChecked: true
        })

        navigate(notification.redirection, {state: {userObj}})
    }

    return (
        <NotificationLayer onClick={onNotificationClick}>
            {profile !== undefined ? (
                <div>
                    <ProfileImage image={profile.profilePhoto}/>
                    {isOnline ? (
                        <OnlineLight></OnlineLight>
                    ) : null}
                    <NotificationContent isChecked={notification.isChecked}>{notification.content}</NotificationContent>
                </div>
            ) : null}
            <NotificationDate>
                <p>{date}</p>
            </NotificationDate>
        </NotificationLayer>
    )
}

function Notification() {
    const [notification, setNotification] = useState<object[]>([])

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        const getNotifications = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/notifications`), (snapshot) => {
            let notificationArray :object[] = []
            snapshot.forEach((doc) => {
                notificationArray.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            setNotification(notificationArray.sort((a :any, b :any) => parseFloat(b.date.seconds) - parseFloat(a.date.seconds)))
        })

        return(() => getNotifications())
    }, [userObj])

    function onDeleteAllClick() {
        notification.forEach((e :any) => {
            deleteDoc(doc(getFirestore(), `users/${userObj.userId}/notifications`, e.id)).then(r => r)
        })
    }

    return (
        <MainPage>
            <MainContent>
                <PageTitle>Notifications</PageTitle>
                <DeleteAllButton>
                    <a onClick={onDeleteAllClick}>delete all</a>
                </DeleteAllButton>
                {notification.length !== 0 ? (
                    notification.map((e :any, i :number) => (
                        <NotificationDetail notification={e} key={i}/>
                    ))
                ) : null}
                <div style={{height: "40px"}}/>
            </MainContent>
            <SideContent pageType={"notification"}/>
        </MainPage>
    )
}

export default Notification