import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {collection, doc, getDoc, getFirestore, onSnapshot} from "firebase/firestore";
import {useSelector} from "react-redux";

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const Modal = styled.div`
    width: 800px;
    max-height: 800px;
    position: fixed;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    text-align: center;
    z-index: 10000;
    overflow-y: auto;

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        min-height: 40%;
        border-radius: 30px 30px 0 0;
    }
`

const UserSearchInput = styled.input`
    width: 400px;
    height: 40px;
    font-size: 30px;
    border: 0;
    border-bottom: 3px solid black;
    outline: none;
    text-align: center;
    margin-bottom: 20px;
  
    @media screen and (max-width: 450px) {
        width: 330px;
    }
`

const FollowingUserSearchLayer = styled.div`
    width: 80%;
    box-shadow: 1px 4px 10px #00000028;
    margin: 20px auto;
    border-radius: 10px;
    padding-top: 10px;
    
    h2 {
        margin: 0;
    }
  
    @media screen and (max-width: 450px) {
        width: 90%;
    }
`

const ProfileId = styled.div`
    margin: 0;
    display: flex;
    
    p {
        font-size: 10px;
        color: #7C7C7C;
        margin: 0 10px 0 0;
    }
`

const ProfileNameLayer = styled.p`
    font-size: 20px;
    margin: 0;
    text-align: left;

    @media screen and (max-width: 620px) {
        width: 130px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`

const UserInfoLayer = styled.div`
    display: flex;
    align-content: center;
`

const UserLayer = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    img {
        border-radius: 90px;
        margin-left: 24px;
        margin-top: 5px;
    }
  
    @media screen and (max-width: 450px) {
        width: 90%;
        
        img {
            margin-left: 0;
        }
    }
`

const ResultLayer = styled.div`
    margin-bottom: 30px;
`

const MentionButton = styled.a`
    padding: 10px 20px;
    background-color: whitesmoke;
    cursor: pointer;
    height: 20px;
    border-radius: 10px;
  
    @media screen and (max-width: 450px) {
        font-size: 15px;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    margin-left: 24px;
    margin-top: 5px;

    @media screen and (max-width: 850px) {
        margin-left: 0;
    }
`

interface MentionModalProp {
    showMentionModal :boolean,
    setShowMentionModal :(active :boolean) => void,
    range :any,
    sel :any,
    setRange :(active :any) => void,
    setSel :(active :any) => void,
    onMention :any
}

function MentionModal({showMentionModal, setShowMentionModal, range, sel, setRange, setSel, onMention} :MentionModalProp) {
    const [searchValue, setSearchValue] = useState<string>("")
    const [followingList, setFollowingList] = useState<object[]>([])
    const [followingSearchResult, setFollowingSearchResult] = useState<object[]>([])

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    function onOverlayClick() {
        setShowMentionModal(false)
        let el = document.createElement("p");
        el.innerHTML = `@`;
        let frag = document.createDocumentFragment(), node, lastNode;
        while ( (node = el.firstChild) ) {
            lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
        if (lastNode) {
            range = range.cloneRange();
            range.setStartAfter(lastNode);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }

        setSel(undefined)
        setRange(undefined)
    }

    function onUserSearchChange(event :any) {
        setSearchValue(event.target.value)
    }

    useEffect(() => {
        const getFollowingUser = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/followings`), (snapshot) => {
            let userList :object[] = []

            snapshot.forEach((FollowDoc) => {
                if (FollowDoc.exists()) {
                    getDoc(doc(getFirestore(), "users", FollowDoc.data().userId)).then((doc) => {
                        if (doc.exists()) {
                            userList.push(doc.data())
                        }
                    })
                }
            })
            setFollowingList(userList)
        })

        return(() => {
            getFollowingUser()
        })
    }, [userObj])

    useEffect(() => {
        if (searchValue.indexOf("@") === 0) {
            if (followingList.length !== 0) {
                setFollowingSearchResult(followingList.filter((e :any) => e.id.search(searchValue.slice(1)) !== -1))
            }
        } else {
            if (followingList.length !== 0) {
                setFollowingSearchResult(followingList.filter((e :any) => e.name.search(searchValue) !== -1))
            }
        }
    }, [searchValue])

    function onMentionClick(user :string) {
        let el = document.createElement("p");
        el.innerHTML = `@${user} `;
        let frag = document.createDocumentFragment(), node, lastNode;
        while ( (node = el.firstChild) ) {
            lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
        if (lastNode) {
            range = range.cloneRange();
            range.setStartAfter(lastNode);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }

        onMention()
        setSel(undefined)
        setRange(undefined)
        setShowMentionModal(false)
    }

    if (showMentionModal) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={onOverlayClick}/>
                <Modal>
                    <h1>Select user</h1>
                    <UserSearchInput type={"text"} placeholder={"Search with ID or name"} onChange={onUserSearchChange}/>
                    <ResultLayer>
                        {followingSearchResult.length !== 0 ? (
                            <FollowingUserSearchLayer>
                                <h2>Following users</h2>
                                {followingSearchResult.map((profile :any, i :number) => (
                                    <UserLayer key={i} onClick={() => onMentionClick(profile.id)}>
                                        <UserInfoLayer>
                                            <a href={"/profile/" + profile.id}>
                                                <ProfileImage image={profile.profilePhoto}/>
                                            </a>
                                            <div style={{margin: "11px", marginBottom: "0", display: "block"}}>
                                                <ProfileNameLayer>{profile.name}</ProfileNameLayer>
                                                <ProfileId>
                                                    <p>{"@" + profile.id}</p>
                                                </ProfileId>
                                            </div>
                                        </UserInfoLayer>
                                        <MentionButton>mention</MentionButton>
                                    </UserLayer>
                                ))}
                            </FollowingUserSearchLayer>
                        ) : null}
                    </ResultLayer>
                </Modal>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default MentionModal