import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {GiphyFetch} from "@giphy/js-fetch-api";
import {Grid} from "@giphy/react-components";
import useWindowDimensions from "../modules/getDisplaySize";

const gf = new GiphyFetch("NSIUcV4v7mGh1Fo3a9WN49VmjjB9KKSs")

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const GifModal = styled.div`
    width: 800px;
    height: 800px;
    position: fixed;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    text-align: center;
    overflow: auto;
    z-index: 10000;

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        min-height: 40%;
        border-radius: 30px 30px 0 0;
    }
`

const GifSearchInput = styled.input`
    width: 400px;
    height: 40px;
    font-size: 35px;
    border: 0;
    border-bottom: 3px solid black;
    outline: none;
    margin-top: 30px;
    text-align: center;
  
    @media screen and (max-width: 850px) {
        width: 80%;
    }
`

const GifGrid = styled.div`
    margin: 30px auto 0;
`

const GifKeywordButton = styled.a`
    background-color: ${props => props.color};
    color: black;
    text-decoration: none;
    padding: 20px 220px;
    font-size: 20px;
    border-radius: 15px;
    display: flex;
    width: 150px;
    justify-content: center;
    margin: 0 auto;
  
    @media screen and (max-width: 620px) {
        padding: 20px 100px;
    }
`

const GifKeywordBox = styled.div`
    margin-bottom: 50px;
    height: 40px;
`

const GifKeywordSelectBox = styled.div`
    margin-top: 50px;
`

const PostingButton = styled.a`
    padding: 10px 230px;
    background-color: #00d2ff;
    color: black;
    text-decoration: none;
    border-radius: 10px;

    @media screen and (max-width: 620px) {
        padding: 7px 143px;
        font-size: 15px;
    }
`

const PostingBox = styled.div`
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 620px) {
        margin-bottom: 15px;
    }
`

interface Iprops {
    isGifModalShow :boolean,
    setIsGifModalShow :(active :boolean) => void,
    setFileType :(active :string) => void,
    setPostFileContent :(active :string) => void
}

function SelectGif({isGifModalShow, setIsGifModalShow, setPostFileContent, setFileType} :Iprops) {
    const [gifKeyword, setGifKeyword] = useState<string>("")
    const [gridWidth, setGridWigth] = useState<number>(700)
    const {width} = useWindowDimensions()

    const fetchGifs = (offset: number) => gf.search(gifKeyword, { offset, limit: 20 })

    function onModalOverlayClick() {
        setGifKeyword("")
        setIsGifModalShow(false)
    }

    function onEnterPress(event :any) {
        setGifKeyword("")
        if (event.key === "Enter") {
            setGifKeyword(event.target.value)
        }
    }

    function onSearchClick() {
        const keywordInput = document.getElementById("gif-keyword-input")
        if (keywordInput instanceof HTMLInputElement) {
            setGifKeyword(keywordInput.value)
        }
    }

    useEffect(() => {
        if (width > 850) {
            setGridWigth(700)
        } else {
            setGridWigth(350)
        }
    })

    if (isGifModalShow) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={onModalOverlayClick}/>
                <GifModal>
                    <GifSearchInput type={"text"} id={"gif-keyword-input"} placeholder={"Search GIFs"} onKeyDown={onEnterPress}/>
                    <PostingBox>
                        <PostingButton onClick={onSearchClick}>Search</PostingButton>
                    </PostingBox>

                    {gifKeyword === "" ? (
                        <GifKeywordSelectBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#3b7ff1"} onClick={() => setGifKeyword("Happy")}>Happy</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#f0c43f"} onClick={() => setGifKeyword("OMG")}>OMG</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#FF4A4A"} onClick={() => setGifKeyword("No")}>No</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#2FD076"} onClick={() => setGifKeyword("Good luck")}>Good luck</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#FF4A4A"} onClick={() => setGifKeyword("High five")}>High five</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#48af30"} onClick={() => setGifKeyword("Clap")}>Clap</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#E3ACCE"} onClick={() => setGifKeyword("Hug")}>Hug</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#3b7ff1"} onClick={() => setGifKeyword("Okay")}>Okay</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#f0c43f"} onClick={() => setGifKeyword("Please")}>Please</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#2FD076"} onClick={() => setGifKeyword("Shockig")}>Shocking</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#ff00b7"} onClick={() => setGifKeyword("I do not know")}>I do not know</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#E3ACCE"} onClick={() => setGifKeyword("Dance")}>Dance</GifKeywordButton>
                            </GifKeywordBox>
                            <GifKeywordBox>
                                <GifKeywordButton href={"#"} color={"#FF4A4A"} onClick={() => setGifKeyword("Hate it")}>Hate it</GifKeywordButton>
                            </GifKeywordBox>
                        </GifKeywordSelectBox>
                    ) : (
                        <GifGrid as={Grid} width={gridWidth} columns={3} fetchGifs={fetchGifs} onGifClick={(gif :any, e :any) => {
                            e.preventDefault();
                            setFileType("gif")
                            setPostFileContent(gif.images.original.url)
                            setIsGifModalShow(false)
                        }}/>
                    )}
                </GifModal>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default SelectGif