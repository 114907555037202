import React, {useEffect} from "react";
import styled from "styled-components";

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 800px;
    max-height: 800px;
    position: fixed;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    text-align: center;
    z-index: 10000;
    overflow-y: auto;

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        min-height: 30%;
        border-radius: 30px 30px 0 0;
    }
`

const ProgressBar = styled.div`
    width: 300px;
    height: 20px;
    background-color: whitesmoke;
    border-radius: 90px;
    margin: 50px auto 0;
    
    & > div {
        height: 100%;
        background-color: #00d2ff;
        border-radius: 90px;
    }
`

interface UploadModalProp {
    uploadingStatus :number | null,
    setUploadingStatus :(active :number | null) => void
}

function UploadModal({uploadingStatus, setUploadingStatus} :UploadModalProp) {
    useEffect(() => {
        if (uploadingStatus === 100) {
            setUploadingStatus(null)
        }
    }, [uploadingStatus])

    if (uploadingStatus !== null) {
        return (
            <ModalOverBox>
                <ModalOverlay/>
                <ModalLayer>
                    <h1>File uploading..</h1>
                    <ProgressBar>
                        <div style={{width: uploadingStatus.toString() + "%"}}/>
                    </ProgressBar>
                    <h1>{uploadingStatus.toFixed()}% done</h1>
                </ModalLayer>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default UploadModal